body,
html {
  height: 100%; /* Ensure full viewport height */
  margin: 0; /* Reset margin */
  background-color: rgba(255, 255, 255, 0.918);

}

#root {
  display: flex;
  flex-direction: column;
  min-height: 100%; /* Take up full height of viewport */
}

#content {
  flex: 1; /* Flex-grow. Ensures the content fills up all available space, pushing the footer down */
}

footer {
  /* padding: 20px; */
  color: #212529;
  background-color: rgb(213, 229, 252);
}

main {
  min-height: 80vh;
  margin: auto;
}

/* .rating span {
  margin: 0.1rem;
}

.rating svg {
  color: orange;
}

.rating-text {
  font-size: 0.8rem;
  font-weight: 600;
  padding-left: 0.5rem;
} */

.product-title {
  height: 2.5em;
  overflow: hidden; 
  text-overflow: ellipsis; 
  white-space: nowrap; 
}

.home-screen-container {
  width: 100%;
  margin-right: 2px;
  padding-right: 10px;
  padding-left: 0;
}
.products-home-col{
  padding: 0 8px;
  margin: 0;
}

table td,
table th {
  text-align: center;
}

/* .review {
  margin-top: 30px;
}

.review h2 {
  font-size: 24px;
  background: #f4f4f4;
  padding: 10px;
  border: 1px solid #ddd;
}

.review button {
  margin-top: 10px;
} */

.logo-image {
  width: 40px;
  height: 40px;
  margin-right: 10px;
}

.Toastify__toast-container--top-right {
  top: calc(3.5rem) !important;
}

/* HomeScreen cards */
.home-product-card .card-img-top {
  width: 100%;
  height: 200px;
  object-fit: cover;
  border-radius: 15px;
}

.nav-tabs .nav-link {
  color: #363f49; /* Bootstrap primary color */
  font-weight: light;
}

.nav-tabs .nav-link.active {
  color: #292424; /* White text color */
  font-weight:bold;
}



.home-product-card {
  background-color: rgb(255, 255, 255);
  width: 110%;
  border-end-end-radius: 15px;
  border-end-start-radius: 15px;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  padding: 0;
  margin: 0;

}



/* Main Image for ProductScreen */
.product-image-container {
  width: 100%;
  height: 300px; /* Adjust this to the height you prefer */
  overflow: hidden;
  position: relative;
  
}
.product-image-container img {
  width: 100%;
  height: 100%;
  object-fit: contain; /* or 'cover' depending on preference */
  display: block;
  border: 1px solid lightgrey;
  border-radius: 10px;

}

.card-col-home{
  padding: 0;
  margin: 0;
}

.carousel-caption {
  color: white;
  font-weight: 900;
  font-size: 2rem;
  font-weight: bolder;
  height: fit-content;
  width: 91%;
  border-radius: 100px;
  position: absolute;
  top: 50%; /* Centers vertically */
  left: 50%; /* Centers horizontally */
  transform: translate(-50%, -50%); /* Shifts the caption */
  background: black;
  opacity: 0.6;
  text-align: center;
  padding: 10px;
  white-space: nowrap; /* Prevents text from wrapping to a new line */
  overflow: hidden; /* Hides any text that overflows the element's width */
  display: flex;
  justify-content: center;
  align-items: center;
}

.caption-text {
  width: 100%;
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.carousel-image {
/* min-height: 400px; */
/* max-height: 400px; */
min-width: 150px;
max-width: 290px;
padding-left: 100px;
padding-right: 30px;
}

.carousel-item{
max-height: 400px;
}

/* Media query for smaller screens */
@media (max-width: 768px) {
  .caption-text {
    width: 70%;
  }
  .carousel-caption {
    width: 100%;
    font-size: 1.6rem;
  }
}



.zoom-effect {
  transition: transform 0.5s ease;
  cursor: pointer;
}

.zoom-effect:hover {
  transform: scale(1.8); /* Adjust the scale value to control the zoom level */
}

.no-transition .carousel-item {
  transition: none !important;
}

.thumbnails-row {
  margin-left: 10px;
  margin-right: 10px;
}
.thumbnail-col {
  width: 15%;
  height: 70px;
  padding: 0;
  margin: 0;
}

.thumbnail-image {
  height: 70px;

  width: 100%;
  margin: 0;
}

.product-name {
  color: #314f6d;
  justify-content: center;
  text-align: center;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  max-width: 100%;
}

.in-stock {
  background-color: rgb(3, 133, 3);
  color: lightcyan;
  padding: 4px 8px;
}

.out-of-stock {
  color: red;
}

.product-details-section {
width: 95%;
margin: auto;
padding-top: 5px;
padding-bottom: 20px;
}

.product-details-section .list-group-item {
  background-color: #f8f8f8;
}

.add-to-cart-section {
  width: 100%;
}

.cart-image-container {
  max-width: 35px;
}

.placeorder-image-container{
  max-width: 20px;
}


.product-price-tag {
  color: rgb(72, 97, 129);
  font-size: 0.75rem;
  font-weight: 500;
}

.in-stock-tag {
  font-weight: bold;
  font-size: 0.6rem;
  line-height: 1.9;
}

.product-dimensions-tag{
  font-size: 0.8rem;
  padding: 0;
  margin: 0;
}

.card-body-home-screen{
  padding: 5px 10px;
}

.price-stock-container {
  display: flex;
  justify-content: space-between;
  align-items: center; /* This ensures vertical centering, if needed */
}

.home-screen-filter-buttons {
  border: 1px rgb(204, 222, 228) solid;
}



.images-gallery {
  max-height: 310px; /* Set a maximum height for the gallery */
  overflow-y: auto; /* Enables vertical scrolling */
  overflow-x: hidden; /* Prevents horizontal scrolling */
}

.image-thumbnail {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  cursor: pointer;
  border: 2px solid transparent;
  transition: border 0.3s ease;
}

.image-thumbnail img {
  width: 100%;
  height: 100px;
  object-fit: cover;
}

.image-thumbnail.selected {
  border-color: #007bff;
}

.image-thumbnail.selected::after {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 123, 255, 0.5);
  z-index: 1;
}

/* Products List Screen */

.id-column {
  max-width: 45px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow:clip;
}

.tight-column {
  white-space: nowrap;
  min-width: 1%;
}
