

.image-card {
    perspective: 1000px;
    position: relative;

  }
  
  .image-card-inner {
    position: relative;
    width: 100%;
    height: 200px; /* Adjust as needed */
    text-align: center;
    transition: transform 0.4s;
    transform-style: preserve-3d;
  }
  
  .image-card:hover .image-card-inner {
    transform: rotateY(180deg);
  }
  
  .image-card-front, .image-card-back {
    position: absolute;
    width: 100%;
    height: 100%;
    backface-visibility: hidden;
  }
  
  .image-card-front {
    background-color: white;
    color: black;
  }
  
  .image-card-front img {
    height: 100%;
    object-fit: cover;
    border: 1px solid lightgray; /* Light border */
    border-radius: 10px; /* Rounded corners */
  }
  
  .image-card-back {
    background-color: white;
    color: black;
    transform: rotateY(180deg);
    border-radius: 10px; /* Match front side's border-radius */
    display: flex;
    flex-direction: column; /* Stack children vertically */
    justify-content: flex-start; /* Align children to the start */
    padding: 10px; /* Add some padding */
}

.image-card-back h5 {
    margin-bottom: 5px; /* Add space below the name */
}

  
  .img-fluid {
    width: 100%; /* Ensure the image takes the full width of its container */
  }

  .image-preview {
    position: relative;
    display: inline-block;
  }
  
  .image-preview img {
    max-width: 80px; /* Adjust as needed */
    height: auto;
  }
  
  .image-preview button {
    position: absolute;
    top: 0;
    right: 0;
  }
  
  .image-card:hover .delete-icon {
    visibility: visible;
}

.delete-icon {
    visibility: hidden;
    position: absolute;
    top: 10px;
    right: 10px;
    /* Add more styling as needed */
}